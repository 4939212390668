import React, {Component} from 'react';
import ContractFormWrapper from "../wrapper-components/ContractFormWrapper";
import Card from "@material-ui/core/Card";
import Fab from "@material-ui/core/Fab";
import {withRouter} from "react-router-dom";

class OwnerFunctions extends Component {
    constructor(props) {
        super(props);
        this.checkIfAdmin = this.checkIfAdmin.bind(this);
        this.navigate = this.navigate.bind(this);
        props.drizzle.contracts.ConfinaleToken.methods.owner().call().then(this.checkIfAdmin);
    }

    checkIfAdmin(address) {
        if (address !== this.props.drizzleState.accounts[0]) {
            return this.navigate();
        }
    }

    navigate() {
        return this.props.history.push('/');
    }

    render() {
        return (
            <div className="section">
                <Card>
                    <div style={{padding: "40px", maxWidth: "700px"}}>
                        <Fab variant="extended" onClick={this.navigate}>Back</Fab>
                        <div className="section">
                            <h3>Initial Send Tokens</h3>
                            <ContractFormWrapper
                                method="initialTransfer"
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                fields={[{label: "Recipient Address", type: "address"},
                                    {label: "Amount to send", type: "number"},
                                    {label: "Waiting Time (s)", type: "number"}]}
                            />

                            <h3>Modify Total Token Supply</h3>
                            <ContractFormWrapper
                                method="modifyTotalSupply"
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                fields={[{label: "Amount", type: "number"}]}
                            />
                            <h3>Fix Value Difference</h3>
                            <ContractFormWrapper
                                method="fixValueDifference"
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                fields={[{label: "Account Address", type: "address"}]}
                            />
                            <h3>Change Owner</h3>
                            <ContractFormWrapper
                                method="changeOwner"
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                fields={[{label: "Account Address", type: "address"}]}
                            />
                        </div>
                    </div>
                </Card>
            </div>

        );
    }
}

export default withRouter(OwnerFunctions);
