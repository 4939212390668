import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

class AdminButton extends Component {

    constructor(props) {
        super(props);
        this.navigateToAdmin = this.navigateToAdmin.bind(this);
    }

    navigateToAdmin() {

        return this.props.history.push('/admin');
    }

    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{marginTop: "10px"}} >
                        <Button variant="contained" startIcon={<SupervisorAccountIcon/>} onClick={this.navigateToAdmin}>Admin Area</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(AdminButton);
