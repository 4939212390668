export function formatExchangedAmount(state) {
    function format(amount) {
        if (amount === 0 || amount.length === 0) {
            return '';
        }
        if (state.totalSupply === 0 || state.totalEther === 0) {
            return '0 ETH';
        }
        let result = state.totalEther * amount  / state.totalSupply;
        return toEther(result);
    }
    return format;
}

export function toEther(wei) {
    let value = wei/1000000000000000000;
    return `${value.toFixed(4)} ETH`;
}

export function formatDateTime(fullDate) {
    let time = formatTime(fullDate);
    if (isToday(fullDate)) {
        return `Today at ~${time}`;
    }
    let date = getDateString(fullDate);
    return `${date} ${formatTime(fullDate)}`;
}

export function formatTime(fullWithdrawDate) {
    return fullWithdrawDate.toLocaleTimeString('de-CH').substring(0, 5);
}

export function getDateString(fullDate) {
    return new Date(fullDate.getTime() - (fullDate.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
}

function isToday(fullDate) {
    let today = getDateString(new Date());
    return getDateString(fullDate) === today;
}

export function toShortAddress(longAddress) {
    let length = longAddress.length;
    let end = longAddress.substring(length - 4, length);
    let start = longAddress.substring(0, 6);
    return `${start}...${end}`;
}

export function getSourceCodeUrl(drizzle) {
    let url = getEtherscanUrl(drizzle);
    let contractAddress = drizzle.contracts.ConfinaleToken.address;
    return `${url}/address/${contractAddress}#code`;
}

function getEtherscanUrl(drizzle) {
    let networkId = drizzle.web3.givenProvider.networkVersion;
    if (networkId === "1") {
        return "https://etherscan.io";
    }
    else if (networkId === "3") {
        return "https://ropsten.etherscan.io";
    }
    return null;
}

export function getTransactionHistoryUrl(drizzleState, drizzle) {
    let url = getEtherscanUrl(drizzle);
    let accountAddress = drizzleState.accounts[0];
    return `${url}/address/${accountAddress}#tokentxns`;
}
