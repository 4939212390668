import React, {Component} from 'react';
import {CircularProgress} from "@material-ui/core";
import Container from "@material-ui/core/Container";

class LoadingComponent extends Component {
    render() {
        return (
            <Container maxWidth="xs" style={{minHeight: "1000px"}} className="center-vert-hor">
                <div style={{textAlign: "center", alignItems: "center"}}>
                    <CircularProgress/>
                </div>
            </Container>
        );
    }
}

export default LoadingComponent;
