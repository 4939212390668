import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {withRouter} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import Button from "@material-ui/core/Button";

class PageNotFound extends Component {

    constructor(props) {
        super(props);
        this.goHome = this.goHome.bind(this);
    }

    goHome() {
        return this.props.history.push('/');
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <h1>Oops, there seems to be nothing here.</h1>

                        </Grid>
                        <Grid item>
                            <Button startIcon={<HomeIcon />} color="primary" variant="contained" onClick={this.goHome}>
                                Take me home
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(PageNotFound);
