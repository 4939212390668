import React, {Component} from 'react';
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

class HoldingTimeTable extends Component {

    render() {
        return (
            <div>
                <TableContainer component={Paper} style={{margin: '20px'}}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Token</TableCell>
                                <TableCell>Available at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tuples.map((tuple, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{tuple.token}</TableCell>
                                    <TableCell align="left">{tuple.withdrawDate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default HoldingTimeTable;
