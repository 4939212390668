import {formatDateTime} from "./formatFunctions";

export function getWithdrawableAmount(address, contract) {
    return new Promise((resolve, reject) => {
        contract.methods.withdrawableAmount(address).call().then(amount => {
            resolve(amount);
        })
    })
}

export function getBalance(address, contract) {
    return new Promise((resolve, reject) => {
        contract.methods.balanceOf(address).call().then(bal => {
            resolve(bal);
        });
    })
}

function getWithdrawSteps(contract, address, bal) {
    return new Promise((resolve, reject) => {
        contract.methods.withdrawSteps(address, bal).call().then(steps => {
            resolve(steps);
        })
    });
}

async function getWithdrawTuples(contract, address, steps) {
    let tuples = [];
    for (let i = 0; i <= steps; i++) {
        let res = await contract.methods.withdrawTupel(address, i).call();
        // add dummy value for nicer display in chart if first tuple is in future
        if (i === 0 && res.holdingPeriod !== "0") {
            tuples.push(mapTuple({"holdingPeriod": 0, "token": 0}));
        }
        // replace first element with latest tuple with a holding period of zero
        if (i > 0 && res.holdingPeriod === "0") {
            tuples[0] = mapTuple(res);
        } else {
            tuples.push(mapTuple(res));
        }
    }
    return new Promise((resolve, reject) => {
        resolve(tuples);
    })
}

export async function getWithdrawData(contract, address) {
    const balance = await getBalance(address, contract);
    const steps = await getWithdrawSteps(contract, address, balance);
    return await getWithdrawTuples(contract, address, steps);
}

function mapTuple(res) {
    let holdingPeriod = parseInt(res.holdingPeriod);
    let formattedDate = "Today";
    let fullDate = new Date();
    if (holdingPeriod > 0) {
        fullDate.setSeconds(fullDate.getSeconds() + parseInt(res.holdingPeriod));
        formattedDate = formatDateTime(fullDate, formattedDate);
    }
    return {
        "token": parseInt(res.token),
        "holdingPeriod": parseInt(res.holdingPeriod),
        "withdrawDate": formattedDate,
        "fullWithdrawDate": fullDate,
    }
}
