import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import UserAccountData from "./UserAccountData";
import TokenTransfer from "../layout/TokenTransfer";

class UserFunctions extends Component {
    render() {
        return (
            <div>
                <div className="section">

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <UserAccountData drizzle={this.props.drizzle} drizzleState={this.props.drizzleState}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TokenTransfer drizzle={this.props.drizzle} drizzleState={this.props.drizzleState}/>
                        </Grid>
                    </Grid>
                </div>
            </div>

        );
    }
}

export default UserFunctions;
