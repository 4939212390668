import React, {Component} from 'react';
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const ethereumAddress = require('ethereum-address');

class ContractFormWrapper extends Component {

    constructor(props) {
        super(props);
        let values = [];
        let fieldErrors = [];
        // init fieldValues with default Values
        for (let i = 0; i < props.fields.length; i++) {
            values.push(this.props.fields[i].type === "text" || this.props.fields[i].type === "address" ? "" : 0);
            fieldErrors.push({hasError: false, message: ''});
        }
        this.state = {fieldValues: values, label: '', fieldErrors: fieldErrors};
        this.handleInput = this.handleInput.bind(this);
        this.submit = this.submit.bind(this);
    }

    async handleInput(e, i, callBack) {
        let values = this.state.fieldValues;
        values[i] = e.target.value;
        let label;
        if (callBack) {
            label = await callBack(e.target.value);
        }
        this.setState({fieldValues: values, label: label});
    }

    submit(e) {
        console.log("Submitted");
        if (!this.validate()) {
            e.preventDefault();
            return;
        }
        console.log("submit is valid");
        if (this.props.payable) {
            this.noArg();
        } else if (this.state.fieldValues.length === 1) {
            this.singleArg();
        } else if (this.state.fieldValues.length === 2) {
            this.twoArgs();
        } else if (this.state.fieldValues.length === 3) {
            this.threeArgs();
        }
        e.preventDefault();
    }

    noArg() {
        this.props.drizzle.contracts.ConfinaleToken.methods[this.props.method].cacheSend({value: this.state.fieldValues[0]})
    }

    singleArg() {
        this.props.drizzle.contracts.ConfinaleToken.methods[this.props.method].cacheSend(this.state.fieldValues[0])
    }

    twoArgs() {
        this.props.drizzle.contracts.ConfinaleToken.methods[this.props.method].cacheSend(this.state.fieldValues[0],
                                                                                         this.state.fieldValues[1])
    }

    threeArgs() {
        this.props.drizzle.contracts.ConfinaleToken.methods[this.props.method].cacheSend(this.state.fieldValues[0],
                                                                                         this.state.fieldValues[1],
                                                                                         this.state.fieldValues[2]);
    }

    render() {
        return (
            <div>
                <form>
                    <Grid container spacing={2} alignItems="center">
                        {this.props.fields.map((field, index) => (
                                <Grid item xs={field.type === 'address' ? 12 : 6} key={index}>
                                    <TextField key={index} variant="outlined"
                                               id={`${this.props.method}_${index}`}
                                               label={field.label}
                                               size="small"
                                               error={this.state.fieldErrors[index].hasError}
                                               helperText={this.state.fieldErrors[index].hasError ? this.state.fieldErrors[index].message : ''}
                                               required
                                               fullWidth={field.type === 'address'}
                                               type={field.type}
                                               value={this.state.fieldValues[index]}
                                               onChange={e => this.handleInput(e, index, field.callBack)}
                                    />
                                </Grid>
                        ))}
                        <Grid item xs={3}>
                            <Button variant="outlined" type="submit" onClick={this.submit}>Submit</Button>
                        </Grid>
                        <Grid item xs={3}>
                            {this.getLabel()}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

    validate() {
        for (let i = 0; i < this.state.fieldValues.length; i++) {
            if (!this.validateField(i)) {
                console.log("error");
                return false;
            }
        }
        console.log("okay");
        return true;
    }

    validateField(index) {
        let fieldValue = this.state.fieldValues[index];
        let fieldType = this.props.fields[index].type;
        let hasError = false;
        let message = "";
        if (fieldType === "address") {
            hasError = !ethereumAddress.isAddress(fieldValue);
            message = hasError ? 'Ethereum address invalid': '';
        } else if (fieldType === "text") {
            hasError = !fieldValue.length > 0;
            message = hasError ? 'Field cannot be empty': '';
        } else if (fieldType === "number") {
            fieldValue = Number(fieldValue);
            hasError = fieldValue <= 0 || !Number.isInteger(fieldValue) || fieldValue > this.props.fields[index].referenceValidationValue;
            message = fieldValue <= 0 ? 'Value must be greater than 0' : !Number.isInteger(fieldValue) ? 'Only whole numbers allowed': '';
            message = fieldValue > this.props.fields[index].referenceValidationValue ? 'Amount is too big': message;
        }
        let fieldErrors = this.state.fieldErrors;
        fieldErrors[index] = {hasError: hasError, message: message};
        this.setState({fieldErrors: fieldErrors});
        return !hasError;
    }

    getLabel() {
        if (this.state.label && this.state.label.length > 0) {
            return (
                <span style={{color: "#12cc97"}}>
                    {this.state.label}
                </span>
            );
        }
        return null;
    }

    getErrorMessage(fieldType) {
        if (fieldType === 'address') {
            return 'Ethereum address invalid';
        } else if (fieldType === 'number') {
            return 'Value must be greater than 0';
        } else if (fieldType === 'text') {
            return 'Field cannot be empty';
        }
        return '';
    }
}

export default ContractFormWrapper;
