
export async function watchToken(drizzle) {
    const tokenAddress = drizzle.contracts.ConfinaleToken.address;
    const tokenSymbol = await getSymbol(drizzle);
    const tokenDecimals = 0;
    const tokenImage = 'https://token.cnfi.ch/ConfinaleTokenLogo.png';

    window.ethereum.sendAsync({
        method: 'wallet_watchAsset',
        params: {
            "type": "ERC20",
            "options": {
                "address": tokenAddress,
                "symbol": tokenSymbol,
                "decimals": tokenDecimals,
                "image": tokenImage,
            },
        },
        id: Math.round(Math.random() * 100000),
    });
}

async function getSymbol(drizzle) {
    return new Promise((resolve, reject) => {
        drizzle.contracts.ConfinaleToken.methods.symbol().call().then(sym => {
            resolve(sym);
        });
    })
}
