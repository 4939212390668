import ConfinaleToken from "./contracts/ConfinaleToken.json";

const options = {
  web3: {
    block: false
  },
  contracts: [ConfinaleToken],
  events: {
    ConfinaleToken: ["Transfer", "TransferWei"],
  },
  polls: {
    accounts: 10000,
    blocks: 3000
  },
};

export default options;
