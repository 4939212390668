import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import * as serviceWorker from './serviceWorker';
import AdminArea from "./admin-area/AdminArea";
import ErrorBoundary from "./wrapper-components/ErrorBoundary";
import PageNotFound from "./error-pages/PageNotFound";
import {DrizzleContext} from "@drizzle/react-plugin";
import drizzleOptions from "./drizzleOptions";
import {toast, ToastContainer} from "react-toastify";
import LoadingComponent from "./wrapper-components/LoadingComponent";
import {DRIZZLE_FAILED, EVENT_FIRED, getToast, TX_BROADCASTED, TX_ERROR} from "./utils/eventNotifierHelper";
import {Drizzle, generateStore} from "@drizzle/store";
import Page from "./layout/Page";
import Header from "./layout/Header";

const contractEventNotifier = store => next => action => {
    if (action.type === EVENT_FIRED || action.type === TX_BROADCASTED ||
        action.type === TX_ERROR || action.type === DRIZZLE_FAILED) {
        const toastMessage = getToast(action, store.getState());
        if (toastMessage) {
            toast(toastMessage.message, toastMessage.options);
        }
    }
    return next(action);
};

const appMiddlewares = [contractEventNotifier];

const drizzleStore = generateStore({drizzleOptions, appMiddlewares, disableReduxDevTools: false});
const drizzle = new Drizzle(drizzleOptions, drizzleStore);

const routing = <Router>
    <ErrorBoundary>
        <DrizzleContext.Provider drizzle={drizzle}>
            <DrizzleContext.Consumer>
                {drizzleContext => {
                    const {drizzle, drizzleState, initialized} = drizzleContext;

                    if (!initialized) {
                        return (
                            <div>
                                <LoadingComponent/>
                                <ToastContainer/>
                            </div>
                        )
                    }

                    return (
                        <div className="App">
                            <Header drizzle={drizzle} drizzleState={drizzleState}/>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Page {...props} drizzle={drizzle} drizzleState={drizzleState}/>)}
                                />
                                <Route
                                    path="/admin"
                                    render={(props) => (
                                        <AdminArea {...props} drizzle={drizzle} drizzleState={drizzleState}/>)}
                                />
                                <Route component={PageNotFound}/>
                            </Switch>
                        </div>
                    )
                }}

            </DrizzleContext.Consumer>
        </DrizzleContext.Provider>
    </ErrorBoundary>
</Router>;

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
