import React, {Component} from 'react';
import {Box} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles';

import { newContextComponents } from "@drizzle/react-components";
import {toEther} from "../utils/formatFunctions";
const { ContractData } = newContextComponents;


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#27588d"
        }
    }
});

class Header extends Component {
    constructor(props) {
        super(props);
        this.getTotalEther = this.getTotalEther.bind(this);
        this.state = {totalEther: "0"};
    }

    async componentDidMount() {
        let total = await this.getTotalEther();
        this.setState({totalEther: toEther(total)})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let newValue = await this.getTotalEther();
        let currentValue = prevState.totalEther;
        if (toEther(newValue) !== currentValue) {
            this.setState({totalEther: toEther(newValue)});
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box bgcolor="primary.main">
                    <div>
                        <Box component="span">
                            <h1 style={{color: "#f0f0f0"}}>
                                Confinale Token
                            </h1>
                        </Box>
                        <span style={{color: "#f0f0f0"}}>
                            <strong>Total Token: </strong>
                            <ContractData
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                contract="ConfinaleToken"
                                method="totalSupply"
                            />{" "}
                            <ContractData
                                drizzle={this.props.drizzle}
                                drizzleState={this.props.drizzleState}
                                contract="ConfinaleToken"
                                method="symbol"
                                hideIndicator
                            /> {"       "}
                        </span>
                        <span style={{color: "#f0f0f0"}}>
                            <strong>Total Ether: </strong>
                            {this.state.totalEther}
                        </span>
                    </div>
                </Box>
            </ThemeProvider>

        );
    }

    async getTotalEther() {
        return new Promise((resolve, reject) => {
            this.props.drizzle.contracts.ConfinaleToken.methods.etherBalance().call().then(total => {
                resolve(total);
            });
        });
    }
}

export default Header;
