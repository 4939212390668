import React, {Component} from 'react';
import { newContextComponents } from "@drizzle/react-components";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {toEther, toShortAddress} from "../utils/formatFunctions";
import {confirmCopyToClipboard} from "../utils/eventNotifierHelper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import {watchToken} from "../utils/watchToken";
const { ContractData } = newContextComponents;


class UserAccountData extends Component {

    constructor(props) {
        super(props);
        this.getAccountTokenSupply = this.getAccountTokenSupply.bind(this);
        this.getAccountValueInEther = this.getAccountValueInEther.bind(this);
        this.addTokenToWallet = this.addTokenToWallet.bind(this);
        this.state = {etherValue: 0, accountSupply: 0};
    }

    async componentDidMount() {
        let accountSupply = await this.getAccountTokenSupply();
        let etherValue = await this.getAccountValueInEther(accountSupply);
        this.setState({etherValue: etherValue, accountSupply: accountSupply});
    }

    async shouldComponentUpdate(nextProps, nextState, nextContext) {
        let newBal = await this.getAccountTokenSupply();
        let newValue = await this.getAccountValueInEther(newBal);
        this.setState({accountSupply: newBal, etherValue: newValue});
        return true;
    }

    render() {
        return (
            <div>
                <Card>
                    <div style={{padding: "20px"}}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <h3>
                                    My Account
                                </h3>
                            </Grid>
                            <Grid item>
                                <Button size="small" endIcon={<AddIcon/>} onClick={this.addTokenToWallet}>Add Token to your wallet</Button>
                            </Grid>
                        </Grid>
                        <hr/>
                            <div>
                                <strong>Address: </strong>
                                <Tooltip title={this.props.drizzleState.accounts[0]}>
                                    <span>
                                        {toShortAddress(this.props.drizzleState.accounts[0])}
                                    </span>
                                </Tooltip>
                                <CopyToClipboard text={this.props.drizzleState.accounts[0]} onCopy={confirmCopyToClipboard}>
                                    <Tooltip title="Copy to Clipboard">
                                        <IconButton aria-label="copy to clipboard">
                                            <FileCopyOutlinedIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                            </div>
                        <strong>Balance: </strong>
                        <ContractData
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            contract="ConfinaleToken"
                            method="balanceOf"
                            methodArgs={[this.props.drizzleState.accounts[0]]}
                        />{" "}
                        <ContractData
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            contract="ConfinaleToken"
                            method="symbol"
                            hideIndicator
                        />
                        {this.getBalanceInEther()}
                        <br/>
                        <strong>Ether Balance: </strong>
                        {toEther(this.props.drizzleState.accountBalances[this.props.drizzleState.accounts[0]])}
                        <br/>
                        <strong>Withdrawable Amount: </strong>
                        <ContractData
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            contract="ConfinaleToken"
                            method="withdrawableAmount"
                            methodArgs={[this.props.drizzleState.accounts[0]]}
                        />{" "}
                        <ContractData
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            contract="ConfinaleToken"
                            method="symbol"
                            hideIndicator
                        />
                    </div>

                </Card>
            </div>
        );
    }

    getBalanceInEther() {
        let etherValue = toEther(this.state.etherValue);
        return ` (${etherValue})`;
    }

    async getAccountValueInEther(tokens) {
        return new Promise((resolve, reject) => {
            this.props.drizzle.contracts.ConfinaleToken.methods.valueConfinaleToken(tokens).call().then(etherValue => {
                resolve(etherValue);
            })
        })
    }

    async getAccountTokenSupply() {
        return new Promise((resolve, reject) => {
            let account = this.props.drizzleState.accounts[0];
            this.props.drizzle.contracts.ConfinaleToken.methods.balanceOf(account).call().then(supply => {
                resolve(supply);
            })
        })
    }

    async addTokenToWallet() {
        await watchToken(this.props.drizzle);
    }
}

export default UserAccountData;
