import React, {Component} from 'react';
import {ResponsiveLine} from "@nivo/line";
import {getDateString} from "../utils/formatFunctions";
import Button from "@material-ui/core/Button";
import SyncIcon from '@material-ui/icons/Sync';
import {toast} from "react-toastify";


class Chart extends Component {

    constructor(props) {
        super(props);
        let dataPoints = this.prepareData(props.data);
        let data = [
            {
                "id": "HoldingTimes",
                "data": dataPoints
            }
        ];
        this.state = {data: data};
        this.updateStateIfPropsChanged = this.updateStateIfPropsChanged.bind(this);
    }

    updateStateIfPropsChanged() {
        let oldTuples = this.state.data[0].data;
        let newTuples = this.prepareData(this.props.data);
        let isEqual = newTuples.length === oldTuples.length && newTuples.every((element, index) => {
            return element.x === oldTuples[index].x && element.y === oldTuples[index].y;
        });
        if (!isEqual) {
            let data = [
                {
                    "id": "HoldingTimes",
                    "data": newTuples
                }
            ];
            this.setState({data: data});
            toast("Data synced", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                type: toast.TYPE.SUCCESS,
                hideProgressBar: true,
                pauseOnFocusLoss: false
            })
        } else {
            toast("Data is up to date", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                type: toast.TYPE.INFO,
                hideProgressBar: true,
                pauseOnFocusLoss: false
            })
        }
    }

    prepareData(dataProps) {
        return dataProps.map(tuple => {
            return  {"x": getDateString(tuple.fullWithdrawDate), "y": tuple.token};
        })
    }

    yFormat(y) {
        return `Token: ${y}`;
    }

    xFormat(x) {
        return `${x}`;
    }

    getMaxYScale() {
        if (this.state.data[0]) {
            let max = this.state.data[0].data.reduce((a,b)=>a.y>b.y?a:b).y;
            return  max + 20;
        }
        return 100;
    }

    render() {
        return (
            <div>
                <div style={{height: "300px", width: "500px"}}>
                    <ResponsiveLine
                        data={this.state.data}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point'}}
                        yScale={{ type: 'linear', min: 0, max: this.getMaxYScale(), stacked: false, reverse: false }}
                        curve="stepAfter"
                        yFormat={this.yFormat}
                        // xFormat={this.xFormat}
                        theme={{
                            tooltip: {
                                container: {
                                    background: '#27588d',
                                    color: 'white',
                                    borderColor: 'white'
                                },
                            },
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Time',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'ConfinaleToken',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        colors={{ scheme: 'category10' }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        useMesh={true}
                    />
                </div>
                <Button variant="contained"
                        onClick={this.updateStateIfPropsChanged}
                        style={{marginBottom: "10px"}}
                        size="small"
                        startIcon={<SyncIcon/>}
                        color="primary"
                >
                    Refresh Chart
                </Button>
            </div>
        );
    }
}

export default Chart;
