import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

import "../App.css";

import OwnerFunctions from "./OwnerFunctions";

class AdminArea extends Component {
    render() {
        return (
                <div>
                    <OwnerFunctions drizzle={this.props.drizzle} drizzleState={this.props.drizzleState} />
                    <ToastContainer/>
                </div>
            );
    }
}

export default AdminArea;
