import React, {Component} from 'react';
import ContractFormWrapper from "../wrapper-components/ContractFormWrapper";
import Card from "@material-ui/core/Card";
import {formatExchangedAmount, getTransactionHistoryUrl, toEther} from "../utils/formatFunctions";
import {getWithdrawableAmount, getBalance} from "../utils/contractHelper";
import Button from "@material-ui/core/Button";
import CallMadeIcon from '@material-ui/icons/CallMade';
import Grid from "@material-ui/core/Grid";

class TokenTransfer extends Component {
    constructor(props) {
        super(props);
        this.getTotalSupply = this.getTotalSupply.bind(this);
        this.getTotalEther = this.getTotalEther.bind(this);
        this.redirectToTrxHistory = this.redirectToTrxHistory.bind(this);
        this.state = {totalSupply: 0, totalEther: 0};
    }

    async componentDidMount() {
        let totalSupply = await this.getTotalSupply();
        let totalEther = await this.getTotalEther();
        let tokenBalance = await getBalance(this.props.drizzleState.accounts[0], this.props.drizzle.contracts.ConfinaleToken);
        let withdrawableAmount = await getWithdrawableAmount(this.props.drizzleState.accounts[0], this.props.drizzle.contracts.ConfinaleToken);
        this.setState({totalSupply: totalSupply, totalEther: totalEther, tokenBalance: tokenBalance, withdrawableAmount: withdrawableAmount});
    }

    render() {
        return (
            <div>
                <Card>
                    <div style={{padding: "20px"}}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <h3>
                                    Transfer
                                </h3>
                            </Grid>
                            <Grid item>
                                <Button size="small" endIcon={<CallMadeIcon/>} onClick={this.redirectToTrxHistory}>Transaction History</Button>
                            </Grid>
                        </Grid>

                        <hr/>
                        <ContractFormWrapper
                            method="transfer"
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            fields={[{label: "Recipient Address", type: "address"}, {label: "Amount", type: "number", referenceValidationValue: this.state.tokenBalance}]}
                        />
                        <h3>Withdraw (Exchange)</h3>
                        <ContractFormWrapper
                            method="withdraw"
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            fields={[{label: "Amount", type: "number", callBack: formatExchangedAmount(this.state), referenceValidationValue: this.state.withdrawableAmount}]}
                        />
                        <h3>Deposit Ether to Smart Contract</h3>
                        <ContractFormWrapper
                            method="deposit"
                            drizzle={this.props.drizzle}
                            drizzleState={this.props.drizzleState}
                            fields={[{label: "Amount (Wei)", type: "number", callBack: toEther}]}
                            payable
                        />
                    </div>

                </Card>
            </div>
        );
    }



    async getTotalSupply() {
        return new Promise((resolve, reject) => {
            this.props.drizzle.contracts.ConfinaleToken.methods.totalSupply().call().then(total => {
                resolve(total);
            });
        });

    }

    async getTotalEther() {
        return new Promise((resolve, reject) => {
            this.props.drizzle.contracts.ConfinaleToken.methods.etherBalance().call().then(total => {
                resolve(total);
            });
        });
    }

    redirectToTrxHistory() {
        let trxUrl = getTransactionHistoryUrl(this.props.drizzleState, this.props.drizzle);
        window.open(trxUrl, '_blank');
    }

}

export default TokenTransfer;
