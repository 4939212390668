import {toast} from "react-toastify";

export const TX_BROADCASTED = 'TX_BROADCASTED';
export const EVENT_FIRED = 'EVENT_FIRED';
export const TX_ERROR = 'TX_ERROR';
export const DRIZZLE_FAILED = 'DRIZZLE_FAILED';

const TRANSFER = 'Transfer';
const WEI_TRANSFER = 'TransferWei';
function getToastOptions() {
    return {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
    }
}

function getToastMessage(action, state) {
    let event = action.event.event;
    let currentAddress = state.accounts[0];
    let message = 'SUCCESS! ';
    let value = action.event.returnValues.value;
    if (event === TRANSFER) {
        let to = action.event.returnValues.to;
        if (to === currentAddress) {
            message += `Received ${value} Tokens`;
        } else {
            message += `Sent ${value} Tokens`;
        }
    } else if (event === WEI_TRANSFER) {
        message += `Transfered ${value} Wei`;
    }
    return message;
}

export function getToast(action, state) {
    let toastMessage = {message: '', options: getToastOptions()};

    if (action.type === EVENT_FIRED) {
        if (! shouldNotifyUser(action, state)) {
            return null;
        }
        toastMessage.message = getToastMessage(action, state);
        toastMessage.options.type = toast.TYPE.SUCCESS;
    } else if (action.type === TX_BROADCASTED) {
        toastMessage.message = "Transaction sent, awaiting Confirmation...";
        toastMessage.options.type = toast.TYPE.INFO;
    } else if (action.type === TX_ERROR) {
        toastMessage.message = `An error occurred: ${action.error.message}`;
        toastMessage.options.type = toast.TYPE.ERROR;
    } else if (action.type === DRIZZLE_FAILED) {
        toastMessage.message = "Could not initialize. You either don't have Metamask installed or you are in incognito mode.";
        toastMessage.options.type = toast.TYPE.ERROR;
        toastMessage.options.autoClose = 60000;
        toastMessage.options.position = 'top-center';
    }
    return toastMessage;
}

function shouldNotifyUser(action, state) {
    let currentAddress = state.accounts[0];
    let sender = action.event.returnValues.from;
    if (action.event.event === WEI_TRANSFER) {
        return sender === currentAddress;
    } else if (action.event.event === TRANSFER) {
        let receiver = action.event.returnValues.to;
        return sender === currentAddress || receiver === currentAddress;
    }
    return false;
}

export function confirmCopyToClipboard() {
    toast("Copied to Clipboard",
        {autoClose: 2000,
            position: "bottom-center",
            hideProgressBar: true,
            type: toast.TYPE.INFO,
            pauseOnFocusLoss: false,
            pauseOnHover: false
        })
}
