import React, {Component} from 'react';
import { newContextComponents } from "@drizzle/react-components";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { getWithdrawData } from "../utils/contractHelper";
import LoadingComponent from "../wrapper-components/LoadingComponent";
import Chart from "../user-area/Chart";
import Card from "@material-ui/core/Card";
import HoldingTimeTable from "../user-area/HoldingTimeTable";
const { ContractData } = newContextComponents;

class Visualization extends Component {
    constructor(props) {
        super(props);
        this.state = {tuples: [], isInit: false}
    }

    async componentDidMount() {
        let address = this.props.drizzleState.accounts[0];
        let contract = this.props.drizzle.contracts.ConfinaleToken;
        const tuples = await getWithdrawData(contract, address);
        this.setState({tuples: tuples, isInit: true});
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let address = this.props.drizzleState.accounts[0];
        let contract = this.props.drizzle.contracts.ConfinaleToken;
        const tuples = await getWithdrawData(contract, address);
        let isEqual = tuples.length === prevState.tuples.length && tuples.every((element, index) => element === prevState.tuples[index]);
        if (!isEqual) {
            this.setState({tuples: tuples});
        }
    }

    showChart() {
        if (this.state.isInit) {
            return (<Chart data={this.state.tuples} />)
        }
        return (<LoadingComponent/>);
    }

    showTable() {
        if (this.state.isInit) {
            return(<HoldingTimeTable tuples={this.state.tuples}/>)
        }
        return (<LoadingComponent/>);
    }

    render() {
        return (
            <Container style={{marginTop: '40px'}} >
                <Card>
                    <h3><ContractData
                        drizzle={this.props.drizzle}
                        drizzleState={this.props.drizzleState}
                        contract="ConfinaleToken"
                        method="symbol"
                        hideIndicator
                    /> Holding Periods</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {this.showTable()}
                        </Grid>

                        <Grid item xs={6}>
                            {this.showChart()}
                        </Grid>

                    </Grid>

                </Card>
            </Container>
        );
    }
}

export default Visualization;
