import React, {Component} from 'react';
import UserFunctions from "../user-area/UserFunctions";
import AdminButton from "../wrapper-components/AdminButton";
import Typography from "@material-ui/core/Typography";
import Visualization from "./Visualization";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import ContractAddressInfo from "./ContractAddressInfo";

class Page extends Component {

    constructor(props) {
        super(props);
        this.setIsAdmin = this.setIsAdmin.bind(this);
        this.state = {isAdmin: false, currentUser: props.drizzleState.accounts[0], isInit: false};
        props.drizzle.contracts.ConfinaleToken.methods.owner().call().then(this.setIsAdmin).then(() => {
            this.setState({isInit: true});
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let newUser = this.props.drizzleState.accounts[0];
        if (newUser !== prevState.currentUser) {
            let owner = await this.props.drizzle.contracts.ConfinaleToken.methods.owner().call();
            this.setState({currentUser: newUser, isAdmin: owner === newUser});
        }
    }

    setIsAdmin(address) {
        let isAdmin = address === this.state.currentUser;
        this.setState({isAdmin: isAdmin});
    }

    adminButton() {
        if (this.state.isAdmin) {
            return (<AdminButton isAdmin={true}/>)
        }
        return null;
    }

    displayVisualizations() {
        if (!this.state.isAdmin && this.state.isInit) {
            return (<Visualization drizzle={this.props.drizzle} drizzleState={this.props.drizzleState}/>);
        }
        return null;
    }

    render() {
        return (
            <div>
                    <ContractAddressInfo drizzle={this.props.drizzle}/>
                    {this.adminButton()}
                    <UserFunctions drizzle={this.props.drizzle} drizzleState={this.props.drizzleState}/>
                    {this.displayVisualizations()}
                    <Typography component="div" style={{minHeight: '100px'}}/>
                    <ToastContainer/>
            </div>
        );
    }
}

export default Page;
