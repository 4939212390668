import React, {Component} from 'react';
import Paper from "@material-ui/core/Paper";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CodeIcon from '@material-ui/icons/Code';
import IconButton from "@material-ui/core/IconButton";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {Tooltip} from "@material-ui/core";
import {confirmCopyToClipboard} from "../utils/eventNotifierHelper";
import {getSourceCodeUrl} from "../utils/formatFunctions";

class ContractAddressInfo extends Component {

    constructor(props) {
        super(props);
        this.redirectToSourceCode = this.redirectToSourceCode.bind(this);
        this.state = {value: props.drizzle.contracts.ConfinaleToken.address};
    }

    displayContractAddress() {
        if (this.state.value) {
            return (
                <span>
                        <div>
                            {this.state.value}
                            <CopyToClipboard text={this.state.value} onCopy={confirmCopyToClipboard}>
                                <Tooltip title="Copy to Clipboard">
                                    <IconButton aria-label="copy to clipboard">
                                        <FileCopyOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                            <Tooltip title="See Source Code">
                                <IconButton onClick={this.redirectToSourceCode} aria-label="see source code">
                                    <CodeIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                </span>
                )
        }
        return null;
    }

    render() {
        return (
            <div>
                <Paper elevation={0}>
                    <strong>Contract Address: </strong>
                    {this.displayContractAddress()}
                </Paper>
            </div>
        );
    }

    redirectToSourceCode() {
        let sourceCodeUrl = getSourceCodeUrl(this.props.drizzle);
        window.open(sourceCodeUrl, '_blank');
    }
}

export default ContractAddressInfo;
